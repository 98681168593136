.container {
    height: 50px;
    background-color: rgb(159, 159, 159);
    text-align: center;
}

.container p {
    line-height: 50px;
    font-weight: bold;
    color: rgb(255, 255, 255);
}