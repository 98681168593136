.container img {
    width: 300px;
    height: 250px;
 }

 .container {
    margin: 10px;
    background-color: #ececec;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 5px 5px 10px rgb(189, 189, 189);
    transition: all 0.3s ease-in-out;
 }

 .container:hover {
   box-shadow: 5px 5px 15px rgb(144, 144, 144);
 }

 .container h3{
    color: #696969;
    margin: 30px 20px 20px;
 }

 .container p {
    margin: 0 25px 20px;
    background-color: rgb(10, 145, 46);
    width: fit-content;
    padding: 5px;
    border-radius: 5px;
    color: #fff;
 }

 .counter { 
   display:  flex;
   align-items: center;
   border-top: 1px solid silver;
 }
 .counter img {
   width: 20px;
   height: fit-content;
   margin: 20px;
   cursor: pointer;
 }

 .counter span {
   background-color: rgb(62, 62, 202);
   padding: 5px;
   border-radius: 5px;
   color: #fff;
   font-weight: bold;
   min-width: 30px;
   text-align: center;
 }

 .deactive { 
   opacity: 0.3;
 }