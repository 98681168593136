.container {
    text-align: center;
    margin: 60px;
}

.container h3 {
    color: #505050;
    font-size: 3rem;
    font-weight: bold;
}

.container div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 40px;
}

.container img {
    width: 300px;
    margin-bottom: 20px;
    padding-left: 5px;
    border-radius: 5px 20px 5px 20px;
}