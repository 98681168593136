.container {
    background-color: #30c0e4;
    padding: 80px 150px ;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media screen and (max-width: 768px) {
    .container {
        flex-direction: column;
        padding: 80px 80px ;
    }
}

.container p {
    font-size: 4rem;
    font-weight: bold;
    color: #fff;
}

@media screen and (max-width: 768px) {
    .container p {
        font-size: 2.5rem;
        text-align: center;
    }
}

.container input {
    max-width: 500px;
    height: 40px;
    border-radius: 10px;
    border: none;
    outline: none;
    padding: 10px;
    font-size: 20px;
}

@media screen and (max-width: 768px) {
    .container input {
        margin-top: 2rem;
        width: 100%;
    }
}

.container input::placeholder {
    font-size: 1rem;
}

.wrapperSearch {
    max-width: 250px;
}

.wrapperSearch span {
    word-wrap: break-word;
}