
.container {
    width: 100%;
    position: relative;
    height: 700px;
}

.container img {
    width: 100%;
    height: 100%;
    border-radius: 0 0 10px 10px;
}

.textContainer {
    position: absolute;
    top: 150px;
    left: 100px;
}

.textContainer h1 {
    font-size: 7rem;
    color: #696969;
    text-shadow: 0 0 10px rgb(196, 249, 251);
}

@media screen and (max-width: 768px) {
    .textContainer h1 {
        font-size: 4rem;
    }
    .textContainer p {
        font-size: 2.5rem;
    }
}

.textContainer p {
    font-size: 4rem;
    margin-top: 20px;
    color: #696969;
    text-shadow: 0 0 2px rgb(255, 255, 255);
}

@media screen and (max-width: 375px) {
    .textContainer {
    left: 50px;
    }
    .textContainer p {
        font-size: 1.5rem;
    }
}

.textContainer span {
    color: #30c0e4;
    font-weight: bold;
    text-shadow: none;
}